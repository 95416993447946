import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import Layout from '../components/layout';
import SEO from '../components/seo';
import {
  MAX_WIDTH,
  MAX_WIDTH_SITE,
  COLOR_GRAY,
  COLOR_WHITE,
  screen,
  StyledA,
  StyledLink,
} from '../components/common/variables';

const Wrapper = styled.div`
  max-width: ${MAX_WIDTH_SITE};
  margin: 0 auto;

  .first-row-container {
    background: ${COLOR_WHITE};
    max-width: ${MAX_WIDTH};
    margin: 0 auto;
    position: relative;
    padding: 44px 35px 44px 35px;
    @media ${screen.largeXL} {
      padding: 90px 40px 200px 40px;
    }
    .four-o-four {
      font-family: sans-serif;
      font-weight: 300;
      font-size: 1.45rem;
      margin-bottom: 8px;
      color: ${COLOR_GRAY};
      @media ${screen.largeXL} {
        font-size: 1.6rem;
      }
    }

    .title {
      font-size: 1.6rem;
      font-weight: 400;
      color: ${COLOR_GRAY};
      max-width: 650px;
      @media ${screen.medium} {
        font-size: 2.1rem;
      }
      @media ${screen.largeXL} {
        font-size: 2.4rem;
      }
    }

    .subtitle {
      color: ${COLOR_GRAY};
      font-weight: 400;
      font-size: 1.2rem;
      margin-top: 10px;
      line-height: 1.8rem;
      max-width: 580px;
      @media ${screen.largeXL} {
        font-size: 1.6rem;
        margin-top: 20px;
        line-height: 2rem;
      }

      &--smaller {
        display: block;
        font-size: 1.3rem;
        font-weight: 300;
        margin-top: 20px;
      }
    }

    .buttons-container {
      display: flex;
      margin-top: 20px;
      justify-content: center;
      a {
        width: 50%;
        text-align: center;
      }
      @media ${screen.medium} {
        justify-content: flex-start;
        a {
          width: auto;
        }
      }
      a:first-child {
        margin-right: 5px;
        @media ${screen.medium} {
          margin-right: 10px;
        }
      }
      a:last-child {
        margin-left: 5px;
        @media ${screen.medium} {
          margin-left: 10px;
        }
      }
    }
  }

  .background-img {
    width: 400px;
    position: absolute;
    top: 0;
    right: 0;
    display: none;
    margin-top: 90px;
    img {
      transform: scaleX(-1);
    }
    @media ${screen.largeXL} {
      display: block;
    }
  }
`;

const NotFoundPage = ({ data }) => {
  const phoneNumber = '1300 739 913';

  return (
    <Layout>
      <SEO title="404: Not found" />
      <Wrapper>
        <div className="first-row-container">
          <h2 className="four-o-four">404</h2>
          <h2 className="title">
            Oops, we can’t seem to find the page you are looking for.
          </h2>
          <p className="subtitle">
            Don’t worry, we recycle EVERYTHING here in our gorgeous bins, so I’m
            sure the information you are looking for is somewhere else on our
            site.
          </p>
          <span className="subtitle subtitle--smaller">
            Give us a call or head back home.
          </span>
          <div className="buttons-container">
            <StyledA transparent="true" href={`tel:${phoneNumber}`}>
              Call
            </StyledA>
            <StyledLink to="/">Home</StyledLink>
          </div>
          <div className="background-img">
            <StaticImage src="../images/demo-error-page.jpg" alt="404" />
          </div>
        </div>
      </Wrapper>
    </Layout>
  );
};

export default NotFoundPage;
